import { EventInput } from '@fullcalendar/common';
import { ReactNode } from 'react';
// ----------------------------------------------------------------------

export type CalendarView = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' | 'listWeek';

export enum IconType {
  SNOWFLAKE = 'SnowFlake',
}

export const USE_DEFAULT_INVOICE_DETAILS_VALUES = null;
export interface invoiceDetailsTypes {
  openBalance: number; // This is the what the total balance due. This is being used to track the amount a client owes the tenant.
  openInvoice: 0; // IDk what this is being used for. Right now it defaults to 0
  nextDueDate: 'today'; // This is the next due date. We should create a new feature that looks at this due date, and handles the email notifications, allowing a guest to pay before. then emails them 3 days again, then on the due date.
  preAuthorizedAmount: number; //This is the amount that is going to be taken.
  totalCompetedServices: number; // This is used to track the total amount of services the guest has. IDK at this point in time if its acutally being used for anything.
  snowPricingStrategy: string;
}

export interface updatingSaveForLaterSummaryTypes {
  openBalance: number; // This is the what the total balance due. This is being used to track the amount a client owes the tenant.
  openInvoice: 0; // IDk what this is being used for. Right now it defaults to 0
  nextDueDate: 'today'; // This is the next due date. We should create a new feature that looks at this due date, and handles the email notifications, allowing a guest to pay before. then emails them 3 days again, then on the due date.
  preAuthorizedAmount: number; //This is the amount that is going to be taken.
  totalCompetedServices: number; // This is used to track the total amount of services the guest has. IDK at this point in time if its acutally being used for anything.
  snowPricingStrategy: string;
  snowPricingStrategyNumberValue: number;
}

export interface ServiceTypesAndDesc {
  id: string | undefined;
  serviceType: string | undefined;
  serviceDescription: string | undefined;
  isRecurring: boolean;
  configs: {
    isSelectedForEstimateFromClientProfile: boolean;
  };
  icon: IconType | null;
}
interface clientContactDialogTypes {
  isOpen: boolean;
  isNotifyButtonLoading: boolean;
  name: string;
  email: string;
  message: string;
  notifyClientResponse: any; //TODO: Update shi
}

interface calendarDetailsTypes {
  isOpen: boolean;
  selectedDate: string;
  isTodaySelected: boolean;
}
interface newServiceDetailsTypes {
  id: string | null;
  serviceType: string | null;
  serviceDescription: string | null;
  serivcePrice: string;
  date: string | null;
}

// This needs to change to update before writing to FB. There is a bug where it doesnt load you to the page, instead it sends you to the list. :(
// And at that point its already saved to the FB wrong.
interface statusTypes {
  activeStep: number;
  clientInitalView: boolean;
  notifications: {
    client: boolean;
    tenant: boolean;
  };
  sentToClientForReview: boolean;
  stage: string | null;
}

export interface crewMembersTypes {
  assignedRoute: null | string;
  firebaseKey: string;
  uid: string;
  businessName: string;
  displayName: string;
  email: string;
  role: string;
  tenantID: string;
  phoneNumber: string;
  federalFee: boolean;
  quickbooksFee: boolean;
  stateFee: boolean;
  workersComp: boolean;
  hourlyRate: string;
}

interface pageEstimateDetailsTypes {
  id: string;
  clientUid: string | null;
  clientViewed: boolean | null;
  contactEmail: string | null;
  contactPhone: string | null;
  dateRequested: string;
  dateScheduled: string | null;
  name: string | null;
  requestList: any;
  sent: boolean;
  status: statusTypes;
  comments: any[] | null;
  summaryCard: {
    totalEstimateDue: number;
  };
  zoneNoteDialog: {
    isOpen: boolean;
    openImageDetails: {
      isOpen: boolean;
      details: any;
    };
    note: string;
    firestoreBucketRef: string | null;
    urlToImage: string | null;
    requestListIndex: number;
    isSaveBtnLoading: boolean;
  };
  priceDialog: {
    isOpen: boolean;
    requestListIndex: number;
    isSaveBtnLoading: boolean;
  };
  serviceDateDialog: {
    isOpen: boolean;
    isSaveBtnLoading: boolean;
  };
  scheduleAcceptedServices: {
    isEnabled: boolean;
  };
}

export interface serviceEditDialogTypes {
  isOpen: boolean;
  editIndex: number;
  sericeType: string | null;
  serviceDescription: string | null;
  servicePrice: number | null;
  serviceDay: string | null;
  serviceAddress: string | null;
  isActive: boolean;
  isRecurring: boolean;
}

export interface selectServiceDateTypes {
  isOpen: boolean;
  serviceAddressIndex: number;
}

export type crewMemberPageTypes = 'LANDING' | 'CREW_DETAILS_EDIT';

export interface updateClientProfileTypes {
  snowMarkersInstalledValue: boolean;
  routeFirebaseIdValue: string;
  routeName: string;
}

export interface closeSFLDocumentFromTenantDashboardTypes {
  nextDueDate: string;
  pricingStategy: string;
}
// export interface saveForMananaSessionsTypes {

// }

export type DashboardState = {
  backdropOpen: boolean;
  isLoading: boolean;
  tenantIdLoaded: boolean;
  clientContactDialogState: clientContactDialogTypes;
  error: Error | string | null;
  events: EventInput[];
  isOpenModal: boolean;
  selectedEventId: null | string;
  selectedRange: null | { start: Date; end: Date };
  clientMessages: any[];
  clientDetails: any[];
  clientMessagesLoaded: boolean;
  hasTenantSetUpStripe: boolean;
  clientListLoaded: boolean;
  estimateListLoaded: boolean;
  clientLoaded: boolean;
  tenantId: string;
  clientList: any[];
  todaysDetails: {
    projectedRevenue: number;
    totalRevenue: number;
  };
  estimateList: any[];
  pendingSchedlingList: any;
  todaysTodos: any;
  businessServices: null | ServiceTypesAndDesc[];
  newEstimateForm: {
    estimateId: string | null;
    clientDetails: any;
    serviceAddressDetails: any;
    estimatePageDetails: any;
    clientId: string;
    totalItems: number;
    lineItems: any | null;
    timeline: any;
  };

  saveForMananaSessions: {
    fetched: boolean;
    sessions: any[] | null;
    dialogDetails: {
      isOpen: boolean;
      details: any;
    };
  };
  routes: routesTypes[] | null;
  routesSnow: {
    fetched: boolean;
    pendingClients: any[] | null;
    routesFetched: boolean;
    routes: any[] | null;
    clientUpdateDialog: {
      isOpen: boolean;
      shouldAckInSnowRoute: boolean;
      routeSelectedValue: string;
      routeSelectedFirebaseID: string | null;
      currentRouteFirebaseUid: string | null;
      isSnowMarked: boolean;
      firebaseUid: string | null; // This is the client UID right now
      title: string | null;
      subtitle: string | null;
      clientFirebaseUid: string | null;
    };
    snowEventData: {
      projectedTotalAccumulation: number;
      projectedStartDateAndTime: Date | null;
      isProjectedDateAndTimeOpen: boolean;
    };
  };
  routeDialog: {
    isOpen: boolean;
    isLoading: boolean;
  };
  serviceCompletedDialog: {
    isOpen: boolean;
    clientName: string | null;
    clientUid: string | null | undefined;
    contactPhone: string | null;
    clientPrice: string | number | null;
    clientAddress: string | null;
    clientServiceDate: string | null;
    scheduledService: string | null;
    serviceDay: string | null;
    serviceDescription: string | null;
  };

  clientOpenProfile: {
    uid: string | null;
    firstName: string | null;
    lastName: string | null;
    billingAddress: string | null;
    billingCity: string | null;
    billingState: string | null;
    billingZip: string | null;
    contactEmail: string | null;
    contactPhone: string | null;
    isActive: boolean;
    serviceAddress: any;
    completedServiceList: any;
    invoice: any[];
    serviceEditDialogState: serviceEditDialogTypes;
    selectServiceDateState: selectServiceDateTypes;
    invoiceDetails: {
      nextDueDate: string;
      openBalance: number;
      preAuthorizedAmount: number;
    };
    estimates: any[];
  };
  newServicePage: {
    step: any;
    dialogSettings: {
      isOpen: boolean;
    };
    serviceAddress: any;
    calendarDetails: calendarDetailsTypes;
    details: newServiceDetailsTypes;
  };
  pageEstimateDetails: pageEstimateDetailsTypes;

  tenantCrewMembersPage: {
    stagingCrewMemberDetails: {
      companyMark: number;
    };
    crewMembers: crewMembersTypes[] | null;
    crewMembersLoaded: boolean;
    sectionView: crewMemberPageTypes;
  };
  snowEventPage: SnowEventPageTypes | null;
  snowRouteDialogConfig: snowRouteDialogConfigTypes;
  snowEventsArray: any[] | null;
  selectedServices: string[];
  /**
   *
   * TODO B4MVP:
   *
   * How we build the data base. Untilemitu it should end up like this in the redux state. but in fire store the client
   * section of the tenant should be
   *
   *
   *  */
};

export type routesTypes = {
  firebaseId: string;
  snpashot: {
    name: string;
    type: 'snow' | 'maintenance';
  };
  clientDetails: {
    activity: any[];
    clientFirebaseUid: string;
    clientName: string;
    completed: boolean;
    contactPhone: string;
    drivewaySize: string | null;
    isShovelingSelected: boolean; // Should default to false
    serviceAddress: string;
  };
};
type snowRouteDialogConfigTypes = {
  isDialogOpen: boolean;
  documentId: string | null;
  dispatched: boolean;
  sessionData: {
    assignedCrewMembers: any[] | null;
    clientDetails: any[] | null;
    routeSnapshot: {
      name: string | null;
      type: string | null;
    };
  };
  completedPercentage: number | null;
  clientDetails: {
    isDialogOpen: boolean;
    activeRouteIndex: number;
    clientDetailsIndex: number;
  };
};

export type newSnowEventTypes = {
  activatedRoutes: any[];
  eventDetails: {
    name: string;
    projectedAccumulationInInches: number;
    actualAccumuilationInInches: number;
    scheduledStartTime: Date;
    actualEndTime: null;
  };
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED';
};

export type SnowEventPageTypes = {
  activatedRoutes: SnowEventDetailsProps[];
  eventDetails: {
    name: string;
    projectedAccumulationInInches: number;
    actualAccumuilationInInches: number;
    scheduledStartTime: Date;
    actualEndTime: null;
  };
  status: SnowEventPageStatusTypes;
  snowEventId: string | undefined;
};

export type SnowEventPageStatusTypes = 'PENDING' | 'ACTIVE' | 'COMPLETED';

export type SnowEventDetailsProps = {
  documentId: string;
  dispatched: boolean;
  sessionData: {
    assignedCrewMembers: any[];
    clientDetails: any[];
    routeSnapshot: {
      name: string;
      type: string;
    };
  };
  completedPercentage: number;
};

export type estimateLineItemStateTypes = {
  serviceType: any;
  serviceDate: any;
  servicePrice: any;
  serviceDescription: string;
};
